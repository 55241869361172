import React, { useEffect, useRef, useState } from "react";
// import dayjs from "dayjs";
// import icon from "../../assets/calendar-icon.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import styles from "./index.module.scss";
// import Calender from "../../assets/Calendar.svg";
import calender from "../../assets/svgs/calender";
import calenderDark from "../../assets/svgs/calenderDark";
import dayjs from "dayjs";
// import { useLocation } from "react-router-dom";

export default function DateInput({
  iconColor,
  theme,
  label = "Readiness Date*",
  handleChange,
  disablePast = false,
  placeholder,
  placeholderShowed = true,
  className = "",
  inputHeight = "",
  dateLabel = "",
  value,
  isPast = false,
  inputClassName = "",
}) {
  // const [selectedDate, setSelectedDate] = useState(null);
  // const location = useLocation();
  // const pathname = location.pathname;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={`${styles["date-input-container"]} ${className}`}>
        <div className={styles["labelView"]}>
          <label className={`${styles["labelView-label"]} ${dateLabel}`}>
            {label}
          </label>
        </div>
        <DatePicker
          label={placeholderShowed ? label : ""}
          disablePast={disablePast}
          value={value ? dayjs(value) : null}
          onChange={(newValue) => {
            handleChange(newValue);
            // setSelectedDate(newValue);
          }}
          slots={{
            openPickerIcon: iconColor === "light" ? calender : calenderDark,
          }}
          className={`${inputHeight} ${isPast && styles["date-input-error"]} ${
            theme ? styles["date-input-dark"] : styles["date-input"]
          } ${inputClassName}`}
          slotProps={{
            textField: {
              placeholder: placeholder,
              InputLabelProps: {
                style: {
                  display: "block",
                  marginBlock: 5,
                  color: "#95A5A6",
                },
              },
              // sx: {
              //   "&::placeholder": {
              //     color: "#95A5A6", // Customize placeholder color
              //     opacity: 1, // Ensures color applies if there's a default lower opacity
              //   },
              // },
            },
            // openPickerIcon:{
            //   style:{

            //   }
            // }
          }}
          sx={
            !theme
              ? {
                  height: "3.2rem",
                  width: "17rem",
                  background: "rgba(255, 255, 255, 0.17)",
                  border: "1px solid rgba(202, 202, 202, 0.5)",
                  borderRadius: "10px",
                  "& .MuiInputBase-input": {
                    fontSize: "0.875rem",
                    color: "black",
                  },
                  width: "100%",
                  background: "#fff",
                  border: " 1px solid #f4f1f1;", // Add drop shadow
                  "& .MuiSelect-select": {
                    // color: "white",
                  },

                  "& .MuiInputBase-input": {
                    // color: "white",
                  },
                  "& .MuiOutlinedInput-input": {
                    // color: "white",
                  },
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      // color: "white", // Ensure this is the most specific selector
                    },
                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", // Change the outline border color to white
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", // Change the outline border color to white on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", // Change the outline border color to white when focused
                    },
                  },
                }
              : {
                  height: "100%",
                  border: "1px solid rgba(202, 202, 202, 0.5)",
                }
          }
        />
        {isPast && <p className={styles["date-error"]}>Enter a future date</p>}
      </div>
    </LocalizationProvider>
  );
}
