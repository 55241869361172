import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import numOfShipmentsIcon from "../../assets/remaining_shipments_icon.svg";
import { getGlobalConfigs } from "../../services/testService";

const RemainingShipments = ({ layout }) => {
  const token = localStorage.getItem("token");
  const [remainingShipmentsNumber, setRemainingShipmentsNumber] = useState(0);

  useEffect(() => {
    const fetchGlobalConfigs = async () => {
      const response = await getGlobalConfigs();
      //console.log("globalConfigs: ", response.settings);
      setRemainingShipmentsNumber(response.settings.number_of_shipments);
    };
    fetchGlobalConfigs();
  }, []);

  return (
    <>
      {token && layout === "sales" && (
        <div className={styles["remaining-shipments"]}>
          <img src={numOfShipmentsIcon} alt="numOfShipmentsIcon" />
          <p>{remainingShipmentsNumber} Remaining</p>
        </div>
      )}
    </>
  );
};

export default RemainingShipments;
