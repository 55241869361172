import styles from "./index.module.scss";
import maerskIcon from "../../assets/maersk-icon.png";
import arrowRight from "../../assets/arrow-right.svg";
import { tableDateFormat } from "../../utils/formatDate";

const DashboardTable = ({ activeRequests }) => {
  return (
    <div className={styles["table-container"]}>
      <table className={styles["shipment-table"]}>
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Time & Date</th>
            <th>Commodity</th>
            <th>Route</th>
            <th>Shipping Line</th>
          </tr>
        </thead>
        <tbody>
          {activeRequests.slice(0, 5)?.map((request) => (
            <tr key={request?.id}>
              <td>{request?.id}</td>
              <td>{tableDateFormat(request?.created_at)}</td>
              <td>{request?.commodity}</td>
              <td className={styles["route-cell-container"]}>
                <div className={styles["route-cell"]}>
                  <div className={styles["route-cell-item"]}>
                    <span className={styles["port-text"]}>
                      {request?.port_of_loading}
                    </span>
                    <br />
                    <span className={styles["country-text"]}>
                      {request?.pol_country_name}
                    </span>
                  </div>
                  <img src={arrowRight} alt="arrow-right" />
                  <div className={styles["route-cell-item"]}>
                    <span className={styles["port-text"]}>
                      {request?.port_of_discharge}
                    </span>
                    <br />
                    <span className={styles["country-text"]}>
                      {request?.pod_country_name}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <img src={maerskIcon} alt={`shipping-line-icon`} />
                {request?.shipping_line?.name ?? "--"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardTable;
