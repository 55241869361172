import React from "react";
import styles from "./index.module.scss";
import initialState from "../../assets/initial.svg";

const EmptyStatus = ({ title, text }) => {
  return (
    <div className={styles["initial-state"]}>
      <img src={initialState} alt="initialState" />
      <h3>{title}</h3>
      {text && <p>{text}</p>}
    </div>
  );
};

export default EmptyStatus;
