import React, { act, useEffect, useState } from "react";
import styles from "./index.module.scss";
import HorizontalTabs from "../../components/HorizontalTabs";
import tick from "../../assets/Select.svg";
import OfferSuccess from "../../assets/offer-success.svg";
import Actions from "../../components/Actions";
import RequestsTable from "../../components/RequestsTable";
import Pagination from "../../components/Pagination";
import BaseModal from "../../components/BaseModal";
import ClientDetails from "../../components/ClientDetails";
import RequestDetails from "../../components/RequestDetails";
import MainButton from "../../components/mainButton";
import OfferForm from "../../components/OfferForm";
import {
  getRequestInfo,
  getSalesRequests,
  toggleStar,
  viewRequest,
} from "../../services/contentService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  selectAttachment,
  selectCriteria,
  selectFromDate,
  selectOpenedRequestID,
  selectToDate,
} from "../../store/slices/salesRequestsSlice";
import Spinner from "../../components/Spinner";
import salesRequests from "../../store/slices/salesRequestsSlice";
import { useNavigate } from "react-router-dom";
import QuoteModal from "../../components/quoteModal";
import EmptyStatus from "../../components/EmptyStatus";
import { setSalesRequestsCount } from "../../store/slices/sidebarSlice";
// HorizontalTabs Icons.
import allIconGrey from "../../assets/all_icon_grey.svg";
import allIconBlue from "../../assets/all_icon_blue.svg";
import pendingIconGrey from "../../assets/pending_icon_grey.svg";
import pendingIconBlue from "../../assets/pending_icon_blue.svg";
import activeIconGrey from "../../assets/active_icon_grey.svg";
import activeIconBlue from "../../assets/active_icon_blue.svg";
import acceptedIconGrey from "../../assets/accepted_icon_grey.svg";
import acceptedIconBlue from "../../assets/accepted_icon_blue.svg";
import expiredIconGrey from "../../assets/expired_icon_grey.svg";
import expiredIconBlue from "../../assets/expired_icon_blue.svg";

function SalesRequests() {
  const [activeTab, setActiveTab] = useState("");
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false);
  const [showSendOfferModal, setShowSendOfferModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [salesRequests, setSalesRequests] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();
  const { requestId } = location.state || {};

  const [modalData, setModalData] = useState(null);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [count, setCount] = useState(null);
  const [order, setOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasRequestId, setHasRequestId] = useState(false);
  const [isTabChanged, setIsTabChanged] = useState(false); // For clearing the filters

  const token = localStorage.getItem("token");
  const select = useSelector;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const from = select(selectFromDate);
  const to = select(selectToDate);
  const criteria = select(selectCriteria);
  const attachment = select(selectAttachment);
  const ID = select(selectOpenedRequestID);
  // const token = localStorage.getItem("token");
  const [requestInfo, setRequestInfo] = useState(null);
  const fetchRequestDetails = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/request?id=${ID}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await getRequestInfo(config);

    if (response?.status === 200) {
      setRequestInfo(response?.data?.data);
      //console.log(response?.data?.data);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const fetchRequestByID = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/request?id=${requestId}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await getRequestInfo(config);

    if (response?.status === 200) {
      setModalData(response?.data?.data);
      setShowOfferModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    if (ID) {
      fetchRequestDetails();
    }
  }, [ID]);
  //console.log(activeTab);
  const tabs = [
    {
      name: "All",
      slug: "",
      inActiveIcon: allIconGrey,
      activeIcon: allIconBlue,
    },
    {
      name: "New",
      slug: "pending",
      inActiveIcon: pendingIconGrey,
      activeIcon: pendingIconBlue,
      count: salesRequests && salesRequests?.count?.pending,
    },

    {
      name: "Active",
      slug: "active",
      inActiveIcon: activeIconGrey,
      activeIcon: activeIconBlue,
    },
    {
      name: "Accepted",
      slug: "accepted",
      inActiveIcon: acceptedIconGrey,
      activeIcon: acceptedIconBlue,
      count: salesRequests && salesRequests?.count?.accepted,
    },
    {
      name: "Expired",
      slug: "expired",
      inActiveIcon: expiredIconGrey,
      activeIcon: expiredIconBlue,
    },
  ];

  const fetchRequests = async () => {
    const formData = new FormData();
    // //console.log(activeTab, order, pageNumber, from, to, searchTerm, criteria);
    // //console.log(criteria);
    if (activeTab !== "") formData.append("status", activeTab);
    if (order !== "") formData.append("order", order);
    if (pageNumber) formData.append("page", pageNumber);
    if (from !== null && !isTabChanged) formData.append("from_date", from);
    if (to !== null && !isTabChanged) formData.append("to_date", to);
    if (searchTerm !== null) formData.append("request_id", searchTerm);
    if (criteria !== "") formData.append("criteria", criteria);
    for (let [key, value] of formData.entries()) {
      //console.log(key + ":", value);
    }
    const queryString = new URLSearchParams(formData).toString();
    const finalQueryString = queryString.replace(
      /criteria=[^&]+/,
      isTabChanged ? "" : `criteria=${criteria}`
    );
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/requests${
        finalQueryString ? `?${finalQueryString}` : ""
      }`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      params: formData,
    };
    setLoading(true);
    const response = await getSalesRequests(config);

    if (response?.status === 200) {
      //console.log(response.data);
      setSalesRequests(response.data);
      dispatch(setSalesRequestsCount(response.data.count));
      setLoading(false);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      setLoading(false);
    } else {
      setLoading(false);
      //console.log(response);
    }
  };

  const handleClose = () => {
    setShowRequestDetailsModal(false);
    setHasRequestId(false);
    // if (salesRequests?.data[0].seen === false || (modalData && !requestInfo))
    if (salesRequests?.data[0].seen === false || isTabChanged) fetchRequests();
    // fetchRequests();
  };

  const toggleFavorite = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/content/request/mark?id=${id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await toggleStar(config);

    if (response?.status === 200) {
      fetchRequests();
      //console.log("success");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const handleSendOffer = () => {
    setShowRequestDetailsModal(false);
    setShowSendOfferModal(true);
    setHasRequestId(false);
    // fetchRequests();
  };
  const handleSend = () => {
    setShowSendOfferModal(false);
    setShowSuccessModal(true);
  };
  const handleSuccess = () => {
    setShowSuccessModal(false);
    // fetchRequests();
  };

  const handleViewClick = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/request/seen?id=${requestId}`,
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const response = await viewRequest(config);
    if (response?.status === 200) {
      // setShowModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [activeTab]);
  useEffect(() => {
    fetchRequests();
  }, [searchTerm]);
  useEffect(() => {
    fetchRequests();
  }, [pageNumber]);
  useEffect(() => {
    if (from === null && to === null) {
      fetchRequests();
    }
  }, [from, to]);

  useEffect(() => {
    if (requestId) {
      setHasRequestId(true);
      fetchRequests();
      fetchRequestByID();
      handleViewClick();
      // setActiveTab(modalData?.request_status);
    }
  }, [requestId]);
  useEffect(() => {
    if (requestId && modalData) {
      // setHasRequestId(true);
      setActiveTab(modalData?.request_status);
      // fetchRequests();
    }
  }, [requestId, modalData]);

  useEffect(() => {
    fetchRequests();
    setPageNumber(1);
  }, [order]);
  useEffect(() => {
    if (ID) {
      fetchRequestDetails();
    }
  }, [ID, showSendOfferModal]);

  // console.log("hasRequestId", hasRequestId);
  // console.log("ID", ID);
  // console.log("requestId", requestId);
  // console.log(hasRequestId ? requestId : ID);
  // console.log("requestInfo", requestInfo);
  // console.log("requestInfo-status", requestInfo?.request_status);
  // console.log(salesRequests?.data[0]);
  // console.log("modalData", modalData);
  // console.log("modalData-status", modalData?.request_status);
  // console.log("active-tab", activeTab);
  // console.log("all-requests", salesRequests); // fetches the data based on the activeTab.
  // console.log("#####".repeat(5));

  return (
    <div className={styles["sales-requests"]}>
      <div className={styles["sales-requests-tabs"]}>
        <HorizontalTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setPageNumber={setPageNumber}
          setIsTabChanged={setIsTabChanged}
          role={"sales"}
          layout={"sales"}
        />
      </div>
      <Actions
        fetchRequests={fetchRequests}
        setOrder={setOrder}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        isTabChanged={isTabChanged}
        setIsTabChanged={setIsTabChanged}
      />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {salesRequests?.data?.length === 0 ? (
            <div className={styles["empty-status"]}>
              <EmptyStatus title="You don’t have any relevant requests under this category." />
            </div>
          ) : (
            <RequestsTable
              setShowModal={setShowRequestDetailsModal}
              salesRequestsData={salesRequests}
              toggleFavorite={toggleFavorite}
              fetchRequests={fetchRequests}
            />
          )}
        </>
      )}

      {salesRequests?.data?.length > 0 && (
        <Pagination
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          itemsPerPage={salesRequests && salesRequests?.count?.division}
          totalItems={salesRequests && salesRequests?.count?.total_count}
        />
      )}
      {/*Request details Modal */}
      <BaseModal
        title={"Client and Request Details"}
        isOpen={
          showRequestDetailsModal ||
          (hasRequestId && modalData?.request_status === "pending")
        }
        handleClose={handleClose}
      >
        <ClientDetails id={hasRequestId ? requestId : ID} />
        <h3 className={styles["sales-requests-h3"]}>Request Details</h3>
        <RequestDetails
          id={hasRequestId ? requestId : ID}
          salesRequests={salesRequests}
        />
        <div className={styles["sales-requests-button"]}>
          {(requestInfo?.request_status === "accepted" && !hasRequestId) ||
          (requestInfo?.request_status === "active" && !hasRequestId) ||
          (hasRequestId && modalData?.request_status === "accepted") ||
          (hasRequestId && modalData?.request_status === "active") ? (
            <MainButton
              className={styles["sales-requests-button-btn-disabled"]}
              // handleSubmit={handleSendOffer}
              disabled={true}
            >
              <img src={tick} />
              <p>Offer Sent</p>
            </MainButton>
          ) : (
            <MainButton
              className={styles["sales-requests-button-btn"]}
              handleSubmit={handleSendOffer}
              disabled={
                requestInfo?.request_status === "expired" ||
                (hasRequestId && modalData?.request_status === "expired")
              }
            >
              Send Offer
            </MainButton>
          )}
        </div>
      </BaseModal>
      {/*Send Offer Modal */}
      <BaseModal
        title={"Submit Quotation"}
        isOpen={showSendOfferModal}
        handleClose={() => setShowSendOfferModal(false)}
      >
        <OfferForm
          setShowSuccessModal={setShowSuccessModal}
          setShowSendOfferModal={setShowSendOfferModal}
          fetchRequests={fetchRequests}
          id={hasRequestId ? requestId : ID}
        />
      </BaseModal>
      {/*Success Modal */}
      <BaseModal
        // title={"Request & Client Details"}
        isOpen={showSuccessModal}
        handleClose={() => {
          setShowSuccessModal(false);
        }}
        isTitle={false}
      >
        <div className={styles["sales-requests-success-modal"]}>
          <img src={OfferSuccess} />
          <h3 className={styles["sales-requests-h3"]}>
            Quotation Submitted Successfully{" "}
          </h3>
          <h6 className={styles["sales-requests-h6"]}>
            The client will be notified of the offer you submitted. Once the
            client accepts the offer, you will also be notified to start
            contacting the client for shipping documents and booking the
            shipment.{" "}
          </h6>

          <div className={styles["sales-requests-button-success"]}>
            <MainButton
              className={styles["sales-requests-button-success-btn"]}
              handleSubmit={handleSuccess}
            >
              Back to Requests
            </MainButton>
          </div>
        </div>
      </BaseModal>
      {showOfferModal && modalData?.request_status !== "pending" && (
        <QuoteModal
          handleClose={() => {
            setShowOfferModal(false);
            setHasRequestId(false);
            if (modalData) fetchRequests();
          }}
          isOpen={showOfferModal}
          title={"Quotation Details"}
          id={modalData.id}
          pdfUrl={modalData.attachment}
          expiration_date={modalData.expiration_date}
          request_status={modalData.request_status}
          role="sales"
        />
      )}
    </div>
  );
}

export default SalesRequests;
