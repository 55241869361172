import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HorizontalTabs from "../../components/HorizontalTabs";
import { getAllRequests, viewRequest } from "../../services/contentService";
import styles from "./index.module.scss";
import RequestCardUpdated from "../../components/requestCardUpdated";
import Pagination from "../../components/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTimelineSkeleton from "../../components/DashboardTimeLineSkeleton";
import QuoteModal from "../../components/quoteModal";
import { setActiveRequestsCount } from "../../store/slices/sidebarSlice";
import EmptyStatus from "../../components/EmptyStatus";
import SuccessModal from "../../components/SuccessModal";
// HorizontalTabs Icons.
import allIconGrey from "../../assets/all_icon_grey.svg";
import allIconBlue from "../../assets/all_icon_blue.svg";
import pendingIconGrey from "../../assets/pending_icon_grey.svg";
import pendingIconBlue from "../../assets/pending_icon_blue.svg";
import activeIconGrey from "../../assets/active_icon_grey.svg";
import activeIconBlue from "../../assets/active_icon_blue.svg";
import acceptedIconGrey from "../../assets/accepted_icon_grey.svg";
import acceptedIconBlue from "../../assets/accepted_icon_blue.svg";
import expiredIconGrey from "../../assets/expired_icon_grey.svg";
import expiredIconBlue from "../../assets/expired_icon_blue.svg";

const Requests = () => {
  const [activeTab, setActiveTab] = useState("");
  const [requests, setRequests] = useState(null);
  const [requestInfo, setRequestInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [hasRequestId, setHasRequestId] = useState(false);

  const token = localStorage.getItem("token");
  const location = useLocation();
  const { requestId } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabs = [
    {
      name: "All",
      slug: "",
      inActiveIcon: allIconGrey,
      activeIcon: allIconBlue,
    },
    {
      name: "Pending",
      slug: "pending",
      inActiveIcon: pendingIconGrey,
      activeIcon: pendingIconBlue,
    },
    {
      name: "Active",
      slug: "active",
      inActiveIcon: activeIconGrey,
      activeIcon: activeIconBlue,
      count: requests?.count?.active,
    },
    {
      name: "Accepted",
      slug: "accepted",
      inActiveIcon: acceptedIconGrey,
      activeIcon: acceptedIconBlue,
    },
    {
      name: "Expired",
      slug: "expired",
      inActiveIcon: expiredIconGrey,
      activeIcon: expiredIconBlue,
    },
  ];

  const getRequests = async () => {
    setLoading(true);
    const response = await getAllRequests(
      activeTab
        ? `status=${
            hasRequestId ? requestInfo?.request_status : activeTab
          }&page=${pageNumber}`
        : `page=${pageNumber}`,
      token
    );
    if (response.status === 200) {
      //console.log(response.data?.count?.total_count);
      setRequests(response.data);
      dispatch(setActiveRequestsCount(response?.data?.count?.active));
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }

    setLoading(false);
  };

  const getRequestInfo = async () => {
    setLoading(true);
    const response = await getAllRequests(`id=${requestId}`, token);
    if (response.status === 200) {
      setRequestInfo(response.data.data[0]);
      setShowOfferModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };

  const handleViewClick = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/request/seen?id=${id}`,
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const response = await viewRequest(config);
    if (response?.status === 200) {
      //console.log("client viewed request");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    getRequests();
    setPageNumber(1);
  }, [activeTab]);
  useEffect(() => {
    getRequests();
  }, [pageNumber]);
  useEffect(() => {
    if (requestId) {
      getRequestInfo();
      getRequests();
    }
  }, [requestId]);
  useEffect(() => {
    if (requestInfo) {
      setHasRequestId(true);
      setActiveTab(requestInfo?.request_status);
      handleViewClick(requestInfo.id);
      getRequests();
    }
  }, [requestInfo]);

  const handleSuccess = () => {
    setHasRequestId(false);
    setActiveTab(requestInfo?.request_status);
    setShowSuccessModal(false);
    getRequests();
  };

  const sidebarWidth = useSelector((state) => state.sidebar.width);

  return (
    <div
      className={styles["requests"]}
      style={{ "--sidebar-width": `${sidebarWidth}px` }}
    >
      <HorizontalTabs
        tabs={tabs}
        activeTab={hasRequestId ? requestInfo?.request_status : activeTab}
        setActiveTab={setActiveTab}
        clientRequests={true}
      />
      {loading ? (
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            rowGap: "0.7rem",
          }}
        >
          <DashboardTimelineSkeleton className={styles["skeleton-styles"]} />
          <DashboardTimelineSkeleton className={styles["skeleton-styles"]} />
          <DashboardTimelineSkeleton className={styles["skeleton-styles"]} />
        </div>
      ) : (
        <>
          {requests?.data?.length === 0 ? (
            <div className={styles["empty-status"]}>
              <EmptyStatus title="You don’t have any relevant requests under this category." />
            </div>
          ) : (
            <div className={styles["requests-container"]}>
              {requests?.data?.map((item) => (
                <RequestCardUpdated
                  key={item.id}
                  request={item}
                  getRequests={getRequests}
                />
              ))}
            </div>
          )}
        </>
      )}
      {requests?.data?.length > 0 && (
        <Pagination
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          itemsPerPage={requests && requests?.count?.division}
          totalItems={requests && requests?.count?.total_count}
        />
      )}
      {/* The quote modal that appears when the user clicks on specific notification */}
      {showOfferModal && requestInfo && (
        <QuoteModal
          handleClose={() => {
            setShowOfferModal(false);
            setHasRequestId(false);
            setActiveTab(requestInfo?.request_status);
            // handleViewClick(requestInfo.id);
            getRequests();
          }}
          isOpen={showOfferModal}
          title={"Quotation Details"}
          id={requestInfo.id}
          pdfUrl={requestInfo.attachment}
          expiration_date={requestInfo.expiration_date}
          request_status={requestInfo.request_status}
          setShowOfferModal={setShowOfferModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      <SuccessModal
        isOpen={showSuccessModal}
        handleSuccess={handleSuccess}
        title="Quotation Accepted"
        body="We are glad you have accepted the submitted quotation. One of our
            sales representatives shall contact you soon either by phone or
            email to arrange for booking your shipment. Please prepare any
            relevant documents such as shipping declaration."
      />
    </div>
  );
};

export default Requests;
