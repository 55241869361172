import { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import SideBar from "../../components/sideBar";
import styles from "./index.module.scss";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setOriginPort,
  setDestinationPort,
  setCargoType,
  setReadyOn,
  setContainer,
  setIsOriginChanged,
  setIsDestinationChanged,
  setIsReadyOnChanged,
  setIsCargoTypeChanged,
  setIsContainerChanged,
} from "../../store/slices/searchSlice";

export function Layout({ children }) {
  const [sideBarShown, setSideBarShown] = useState(window.innerWidth > 768);
  const [overlay, setOverlay] = useState(false);
  const [webZoom, setWebZoom] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const windowSize = window.innerWidth;
    setWebZoom(windowSize / 1920);
  }, []);

  // Resetting Ship Schedules Search Global State on Route Change.
  useEffect(() => {
    if (location.pathname !== "/") {
      dispatch(setOriginPort(""));
      dispatch(setDestinationPort(""));
      dispatch(setCargoType(""));
      dispatch(setContainer({ name: "", type: "", count: 1 }));
      dispatch(setReadyOn("2024-12-02"));

      dispatch(setIsOriginChanged(false));
      dispatch(setIsDestinationChanged(false));
      dispatch(setIsReadyOnChanged(false));
      dispatch(setIsCargoTypeChanged(false));
      dispatch(setIsContainerChanged(false));
    }
  }, [location.pathname]);

  return (
    <div
      className={styles["layout-conatiner"]}
      style={{ "--web-zoom": `${webZoom}` }}
    >
      <Header handleSideBar={setSideBarShown} handleOverlay={setOverlay} />
      {overlay ? <div className={styles["overlay"]}></div> : null}
      {sideBarShown ? (
        <SideBar handleSideBar={setSideBarShown} handleOverlay={setOverlay} />
      ) : null}
      <main className={styles["layout-main"]}>{children}</main>
      {/* <Footer /> */}
    </div>
  );
}

export default Layout;
