import React from "react";
import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/userSlice";
import DashboardCard from "../../components/DashboardCard";
import dashboardShip from "../../assets/dashboard-ship.svg";
import dashboardRequests from "../../assets/dashboard-requests.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTimeline from "../../components/DashboardTimeline";
import DashboardTable from "../../components/DashboardTable";
import {
  getDashboradRequests,
  getDashboradShipments,
} from "../../services/contentService";
import DashboardCardSkeleton from "../../components/DashboardCardSkeleton";
import DashboardTimelineSkeleton from "../../components/DashboardTimeLineSkeleton";
import ShipmentTrackingMap from "../../components/Map";

import map from "../../assets/map.png"; // For Now
import EmptyStatus from "../../components/EmptyStatus";

const Dashboard = () => {
  const sidebarWidth = useSelector((state) => state.sidebar.width);
  const { profile } = useSelector(userData);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");

  const [activeRequests, setActiveRequests] = useState({});
  const [lastestShipment, setLastestShipment] = useState({});
  const [isActiveRequestsLoading, setIsActiveRequestsLoading] = useState(false);
  const [isLatestShipmentsLoading, setIsLatestShipmentsLoading] =
    useState(false);

  const getActiveRequests = async () => {
    setIsActiveRequestsLoading(true);
    const response = await getDashboradRequests(token);
    if (response.status === 200) {
      setActiveRequests(response?.data);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
    setIsActiveRequestsLoading(false);
  };
  const getLatestShipment = async () => {
    setIsLatestShipmentsLoading(true);
    const response = await getDashboradShipments(token);
    if (response.status === 200) {
      setLastestShipment(response?.data);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
    setIsLatestShipmentsLoading(false);
  };
  useEffect(() => {
    getActiveRequests();
    getLatestShipment();
  }, []);

  return (
    <div
      className={styles["dashboard"]}
      style={{ "--sidebar-width": `${sidebarWidth}px` }}
    >
      <div className={`${styles["dashboard-row"]}`}>
        <div className={`${styles["dashboard-row-heading"]}`}>
          <h3>
            Welcome {profile?.first_name} <br />
            <span>Here’s what’s happening with your cargo today</span>
          </h3>
        </div>
      </div>
      <div className={`${styles["dashboard-row"]}`}>
        <div className={`${styles["dashboard-row-cards"]}`}>
          {isLatestShipmentsLoading ? (
            <DashboardCardSkeleton />
          ) : (
            <DashboardCard
              number={lastestShipment?.count ?? 0}
              title="Active Shipments"
              icon={dashboardShip}
            />
          )}
          {isActiveRequestsLoading ? (
            <DashboardCardSkeleton />
          ) : (
            <DashboardCard
              number={activeRequests?.count}
              title="Active Requests"
              icon={dashboardRequests}
            />
          )}
          <div className={styles["unique-card"]}>
            <p>Receive competitive rates for your shipments.</p>
            <button
              type="button"
              onClick={() => {
                navigate("/request-quote/shipping-details", {
                  state: { previousPath: location.pathname },
                });
              }}
            >
              Request Quote
            </button>
          </div>
        </div>
      </div>
      <div className={`${styles["dashboard-row"]}`}>
        <div className={`${styles["dashboard-row-heading"]}`}>
          <h3>Latest Active Shipment</h3>
          <a onClick={() => navigate("/shipment-tracking")}>View All</a>
        </div>
        <div className={styles["map-request"]}>
          {/* <img src={map} alt="map" className={styles["map-img"]} /> */}
          <ShipmentTrackingMap />
          {isLatestShipmentsLoading ? (
            <DashboardTimelineSkeleton />
          ) : (
            <>
              {lastestShipment?.data ? (
                <DashboardTimeline
                  lastestShipment={lastestShipment?.data ?? []}
                />
              ) : (
                <EmptyStatus
                  title="You Don’t Have Any Active Shipments."
                  text="Visit Shipments Tracking page to see other shipments you
                    might have, or request a quotation on a new shipment."
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className={`${styles["dashboard-row"]}`}>
        <div className={`${styles["dashboard-row-heading"]}`}>
          <h3>Latest Active Requests</h3>
          <a onClick={() => navigate("/requests")}>View All</a>
        </div>
        {activeRequests?.data?.length ? (
          <DashboardTable activeRequests={activeRequests?.data ?? []} />
        ) : (
          <EmptyStatus
            title="You Don’t Have Any Active Requests."
            text="Visit Requests page to see status of other requests, or request a
              quotation on a new shipment."
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
