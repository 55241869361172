import { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import SideBar from "../../components/sideBar";
import styles from "./index.module.scss";

export function HeaderLayout({ children }) {
  const [sideBarShown, setSideBarShown] = useState(window.innerWidth > 768);
  const [overlay, setOverlay] = useState(false);
  const [webZoom, setWebZoom] = useState(0);

  useEffect(() => {
    const windowSize = window.innerWidth;
    setWebZoom(windowSize / 1920);
  }, []);
  return (
    <div
      className={styles["layout-conatiner"]}
      style={{ "--web-zoom": `${webZoom}` }}
    >
      <Header handleSideBar={setSideBarShown} handleOverlay={setOverlay} />
      {overlay ? <div className={styles["overlay"]}></div> : null}

      <main className={styles["layout-main"]}>{children}</main>
      {/* <Footer /> */}
    </div>
  );
}

export default HeaderLayout;
