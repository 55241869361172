import styles from "./index.module.scss";

const HorizontalTabs = ({
  activeTab,
  tabs,
  setActiveTab,
  setPageNumber,
  role,
  layout = "user",
  clientRequests = false,
  setIsTabChanged,
}) => {
  return (
    <div className={styles["container"]}>
      {tabs.map((item) => (
        <button
          onClick={() => {
            setActiveTab(item.slug);
            if ((layout = "sales" && role === "sales")) {
              setPageNumber(1);
              setIsTabChanged(true);
            }
          }}
          key={item.slug}
          className={`${styles["container-tab-container"]} ${
            item.slug == activeTab ? styles["container-tab-active"] : ""
          }`}
        >
          {(role == "sales" || clientRequests) && (
            <div className={styles["tabs-icon-container"]}>
              {item.slug === activeTab ? (
                <img
                  src={item.activeIcon}
                  alt="horizontalTabs-icon"
                  width={24}
                  height={24}
                />
              ) : (
                <img
                  src={item.inActiveIcon}
                  alt="horizontalTabs-icon"
                  width={24}
                  height={24}
                />
              )}
            </div>
          )}
          <h6>{item.name}</h6>
          {layout === "sales"
            ? (item.slug === "accepted" || item.slug === "pending") &&
              item.count !== 0 && (
                <div className={styles["container-tab-container-count"]}>
                  <p>{item.count}</p>
                </div>
              )
            : item.slug === "active" &&
              item.count !== 0 && (
                <div className={styles["container-tab-container-count"]}>
                  <p>{item.count}</p>
                </div>
              )}
        </button>
      ))}
    </div>
  );
};

export default HorizontalTabs;
