// import Navbar from '../../components/navbar'
// import Footer from '../../components/footer'
import styles from "./index.module.scss";

export function ProtectedRouteLayout({ children }) {
  return (
    <div>
      {/* <Navbar /> */}
      <main className={styles["layout-main"]}>{children}</main>
      {/* <Footer /> */}
    </div>
  );
}

export default ProtectedRouteLayout;
