import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import maersk from "../../assets/maersk.png";
import ship from "../../assets/ship2.svg";
import etdIcon from "../../assets/ETD-icon.svg";
import etaIcon from "../../assets/ETA-icon.svg";
import MainButton from "../mainButton";
import formatDate from "../../utils/formateDate";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";

export default function FreightCard({
  freight,
  globalConfigs,
  shouldShowPrice,
  type,
  freightsSearch,
  portOfLoading,
  portOfDischarge,
  cargo,
  container,
  startingFromDate,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    origin_country: originCountry,
    origin_country_flag: originCountryFlag,
    destination_country: destinationCountry,
    destination_country_flag: destinationCountryFlag,
    ETA,
    ETD,
    price,
    vessel_id: vessel,
    transit_time: transit,
    shipping_line: shippingLineScac,
    shipping_line_name: shippingName,
    destination_port_name: destiantionPortName,
    origin_port_name: originPortName,
  } = freight;

  const formattedETA = formatDate(ETA);
  const formattedETD = formatDate(ETD);

  const shouldShowShippingLine = () => {
    const userTier = localStorage.getItem("user-tier"); // premium-standard
    const displayShippingLine = globalConfigs.display_shipping_lines; // all-none-premium-logged

    if (
      (displayShippingLine === "all" && userTier) ||
      (displayShippingLine === "premium" && userTier === "premium") ||
      (displayShippingLine === "logged" && userTier)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles["card"]}>
      <div className={styles["card-footer"]}>
        {shouldShowShippingLine() ? (
          <div className={styles["shipping-line"]}>
            <img src={maersk} />
            <p>{shippingName}</p>
          </div>
        ) : (
          <div></div>
        )}

        <p className={styles["card-id"]}>{vessel}</p>
      </div>
      <div className={styles["card-header"]}>
        {/* The Updated Card */}
        <div className={styles["card-shipping-row"]}>
          {/* Port Of Loading */}
          <div className={styles["card-shipping-row-item"]}>
            <div className={styles["card-top"]}>
              <div className={styles["card-top-icon"]}>
                <img src={etdIcon} alt="etdIcon" />
                <span>ETD</span>
              </div>
              <p>{moment(ETD).format("D MMM YYYY")}</p>
            </div>
            <img src={originCountryFlag} />
            <p className={styles["card-shipping-row-item-strong"]}>
              {originPortName}
            </p>
            <p className={styles["card-shipping-row-item-country"]}>
              {originCountry}
            </p>
          </div>
          {/* The Blue Separator */}
          <div className={styles["card-shipping-row-separator-container"]}>
            <div className={styles["transit"]}>
              <img src={ship} />
              <span>{transit} Days</span>
            </div>
            <div
              className={`${styles["card-shipping-row-separator"]} ${styles["card-shipping-row-blue-separator"]}`}
            ></div>
          </div>
          {/* Port Of Discharge */}
          <div className={styles["card-shipping-row-item"]}>
            <div className={styles["card-top"]}>
              <div className={styles["card-top-icon"]}>
                <img src={etaIcon} alt="etaIcon" />
                <span>ETA</span>
              </div>
              <p>{moment(ETA).format("D MMM YYYY")}</p>
            </div>
            <img src={destinationCountryFlag} />
            <p className={styles["card-shipping-row-item-strong"]}>
              {destiantionPortName}
            </p>
            <p className={styles["card-shipping-row-item-country"]}>
              {destinationCountry}
            </p>
          </div>
        </div>

        <div className={styles["quote"]}>
          {shouldShowPrice && price ? (
            <div className={styles["price"]}>
              <p>
                <span>Estimated Price</span>
                <span>Not Actual</span>
              </p>
              <p>${price}</p>
            </div>
          ) : (
            <div></div>
          )}

          {type === "sales" ? null : (
            <MainButton
              handleSubmit={() =>
                navigate("/request-quote/shipping-details", {
                  state: {
                    freight,
                    previousPath: location.pathname,
                    freightsSearch: freightsSearch,
                    schdulesSearchValues: {
                      pol: portOfLoading,
                      pod: portOfDischarge,
                      cargo: cargo,
                      container: container,
                      startingFromDate: startingFromDate,
                      shouldShowShippingLine: shouldShowShippingLine(),
                    },
                  },
                })
              }
              className={styles.frieghtButtonClassName}
            >
              Get a Quote
            </MainButton>
          )}
        </div>
      </div>
    </div>
  );
}
