import React from "react";
import BaseModal from "../BaseModal";
import MainButton from "../mainButton";
import OfferSuccess from "../../assets/offer-success.svg";
import styles from "./index.module.scss";

const SuccessModal = ({ isOpen, handleSuccess, title, body }) => {
  return (
    <BaseModal isOpen={isOpen} isTitle={false}>
      <div className={styles["success-modal"]}>
        <img src={OfferSuccess} />
        <h3 className={styles["success-modal-h3"]}>{title}</h3>
        <h6 className={styles["success-modal-h6"]}>{body}</h6>

        <div className={styles["success-modal-button-success"]}>
          <MainButton
            className={styles["success-modal-button-success-btn"]}
            handleSubmit={handleSuccess}
          >
            Back to Requests
          </MainButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default SuccessModal;
