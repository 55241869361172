import { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import SideBar from "../../components/sideBar";
import styles from "./index.module.scss";
import { useLocation } from "react-router-dom";

export function SalesRouteLayout({ children }) {
  const location = useLocation();
  let pathname = location.pathname;
  //console.log(pathname);
  const [sideBarShown, setSideBarShown] = useState(window.innerWidth > 768);
  const [overlay, setOverlay] = useState(false);
  const [webZoom, setWebZoom] = useState(0);

  useEffect(() => {
    const windowSize = window.innerWidth;
    setWebZoom(windowSize / 1920);
  }, []);
  return (
    <div
      className={styles["layout-conatiner"]}
      style={{ "--web-zoom": `${webZoom}` }}
    >
      <Header
        handleSideBar={setSideBarShown}
        handleOverlay={setOverlay}
        layout="sales"
      />
      {sideBarShown
        ? pathname !== "/shipment-info" && (
            <SideBar
              handleSideBar={setSideBarShown}
              handleOverlay={setOverlay}
              layout="sales"
            />
          )
        : null}
      <main className={styles["layout-main"]}>{children}</main>
      {/* <Footer /> */}
    </div>
  );
}

export default SalesRouteLayout;
