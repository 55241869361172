import styles from "./index.module.scss";
import clock from "../../assets/clock-icon.svg";
import moment from "moment/moment";
import ExpandMore from "../expandMore";
import expandIcon from "../../assets/collapse-arrow.svg";
import stateIcon from "../../assets/state.svg";
import truck from "../../assets/truck2.svg";
import ship from "../../assets/ship2.svg";
import { memo, useEffect, useState } from "react";
import MainButton from "../mainButton";
import { Collapse } from "@mui/material";
import Carousel from "../carousel";
import QuoteModal from "../quoteModal";
import SuccessModal from "../SuccessModal";
import { viewRequest } from "../../services/contentService";
import { useNavigate } from "react-router-dom";

const RequestCard = ({ request, getRequests = () => null }) => {
  const token = localStorage.getItem("token");
  const {
    id,
    created_at,
    request_status,
    origin_state,
    origin_country,
    pol_country_flag,
    pol_country_name,
    seen,
    pod_country_flag,
    pod_country_name,
    port_of_loading,
    port_of_discharge,
    required_services,
    invoice_value,
    destination_country,
    destination_state,
    readiness_date,
    shipping_line,
    commodity,
    containers,
    origin_address,
    destination_address,
    pod_free_time,
    target_rate,
    additional_info,
    expiration_date,
    attachment,
    dangerous_cargo,
  } = request;

  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleViewClick = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/request/seen?id=${id}`,
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const response = await viewRequest(config);
    if (response?.status === 200) {
      //console.log("client viewed request");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (request_status === "active" && expanded === true && seen === false) {
    }
  };

  const handleSuccess = () => {
    setShowSuccessModal(false);
    getRequests();
  };

  return (
    <div className={styles["card"]}>
      <div className={`${styles["card-row"]} ${styles["card-row-bottom"]}`}>
        <p> ID: {id}</p>
        <div className={styles["card-row-time"]}>
          <img src={clock} alt="clock" />
          <p>{moment(created_at).format("h:mm A, D MMM YYYY")}</p>
        </div>
        <div className={styles["card-row-status"]}>
          <p
            className={
              request_status === "accepted"
                ? styles["card-row-status-accepted"]
                : request_status === "expired"
                ? styles["card-row-status-rejected"]
                : request_status === "pending"
                ? styles["card-row-status-pending"]
                : request_status === "active" &&
                  styles["card-row-status-active"]
            }
          >
            {request_status}
          </p>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <img src={expandIcon} />
          </ExpandMore>
        </div>
      </div>
      {/* Request Card TimeLine */}
      <div className={styles["card-shipping-row"]}>
        {/* Origin Content */}
        {(origin_state || origin_country) && (
          <div
            className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-origin"]}`}
          >
            <>
              <img src={stateIcon} />
              {origin_state && (
                <p className={styles["card-shipping-row-item-strong"]}>
                  {origin_state}
                </p>
              )}
              {origin_country && (
                <p className={styles["card-shipping-row-item-country"]}>
                  {origin_country}
                </p>
              )}
            </>
          </div>
        )}
        {/* Origin Separator */}
        {(origin_state || origin_country) && (
          <div
            className={`${styles["card-shipping-row-separator-container"]} 
            ${styles["card-shipping-row-separator-container-origin"]}`}
          >
            <img src={truck} />
            <div className={styles["card-shipping-row-separator"]}></div>
          </div>
        )}
        {/* Port Of Loading */}
        <div
          className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-pol"]}`}
        >
          <img src={pol_country_flag} />
          <p className={styles["card-shipping-row-item-strong"]}>
            {port_of_loading}
          </p>
          <p className={styles["card-shipping-row-item-country"]}>
            {pol_country_name}
          </p>
        </div>
        {/* The Blue Separator */}
        <div className={styles["card-shipping-row-separator-container"]}>
          <img src={ship} />
          <div
            className={`${styles["card-shipping-row-separator"]} ${styles["card-shipping-row-blue-separator"]}`}
          ></div>
        </div>
        {/* Port Of Discharge */}
        <div
          className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-pod"]}`}
        >
          <img src={pod_country_flag} />
          <p className={styles["card-shipping-row-item-strong"]}>
            {port_of_discharge}
          </p>
          <p className={styles["card-shipping-row-item-country"]}>
            {pod_country_name}
          </p>
        </div>
        {/* Destination Separator */}
        {(destination_state || destination_country) && (
          <div
            className={`${styles["card-shipping-row-separator-container"]} 
          ${styles["card-shipping-row-separator-container-destination"]}`}
          >
            <img src={truck} />
            <div className={styles["card-shipping-row-separator"]}></div>
          </div>
        )}
        {/* Destination Content */}
        {(destination_state || destination_country) && (
          <div
            className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-destination"]}`}
          >
            <img src={stateIcon} />
            {destination_state && (
              <p className={styles["card-shipping-row-item-strong"]}>
                {destination_state}
              </p>
            )}
            {destination_country && (
              <p className={styles["card-shipping-row-item-country"]}>
                {destination_country}
              </p>
            )}
          </div>
        )}
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {/* Requests Details For Responsive View */}
        <div className={styles["card-collapse-res"]}>
          <Carousel carouselArrows={styles.carouselArrows}>
            {/* Shipping Details Information */}
            {(shipping_line?.name ||
              pod_free_time > 0 ||
              required_services?.length > 0 ||
              invoice_value > 0) && (
              <div className={styles["card-collapse-step"]}>
                <h3>Shipping</h3>
                <div className={styles["card-collapse-step-data"]}>
                  {shipping_line?.name && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Shipping Line
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {shipping_line?.name}
                      </p>
                    </div>
                  )}
                  {pod_free_time > 0 && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        POD Free Time
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {pod_free_time} days
                      </p>
                    </div>
                  )}
                  {required_services && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Required Services
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {required_services?.map((item) => (
                          <div key={item.id}>
                            {item.title}
                            <br />
                          </div>
                        ))}
                      </p>
                    </div>
                  )}
                  {invoice_value > 0 && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Invoice Value
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {invoice_value}$
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* Truking Informations */}
            {/* {(origin_country || destination_country) && ( */}
            {(origin_country ||
              origin_state ||
              origin_address ||
              destination_country ||
              destination_state ||
              destination_address) && (
              <div className={styles["card-collapse-step"]}>
                <h3>Trucking</h3>
                <div className={styles["card-collapse-step-data"]}>
                  {origin_country && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Origin Country
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {origin_country}
                      </p>
                    </div>
                  )}
                  {origin_state && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Origin State
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {origin_state}
                      </p>
                    </div>
                  )}
                  {origin_address && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        origin Address
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {origin_address}
                      </p>
                    </div>
                  )}
                  {destination_country && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Destination Country
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {destination_country}
                      </p>
                    </div>
                  )}
                  {destination_state && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Destination State
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {destination_state}
                      </p>
                    </div>
                  )}
                  {destination_address && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Destination Address
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {destination_address}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Cargo Details Information */}
            {(readiness_date ||
              commodity ||
              containers?.length > 0 ||
              dangerous_cargo !== undefined) && (
              <div className={styles["card-collapse-step"]}>
                <h3>Cargo Details</h3>
                <div className={styles["card-collapse-step-data"]}>
                  <div className={styles["card-collapse-step-data-item"]}>
                    <p className={styles["card-collapse-step-data-item-label"]}>
                      Readiness Date
                    </p>
                    <p className={styles["card-collapse-step-data-item-value"]}>
                      {moment(readiness_date).format("D MMM YYYY")}
                    </p>
                  </div>
                  {commodity && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Commodity
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                        style={{
                          minWidth: "250px",
                          maxWidth: "250px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                          overflow: "hidden",
                        }}
                      >
                        {commodity}
                      </p>
                    </div>
                  )}
                  <div className={styles["card-collapse-step-data-item"]}>
                    <p className={styles["card-collapse-step-data-item-label"]}>
                      Dangerous Cargo
                    </p>
                    <p className={styles["card-collapse-step-data-item-value"]}>
                      {dangerous_cargo ? "Yes" : "No"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                    }}
                  >
                    {containers?.length > 0 ? (
                      <div className={styles["card-collapse-step-data-item"]}>
                        <p
                          className={
                            styles["card-collapse-step-data-item-label"]
                          }
                        >
                          Containers
                        </p>
                        <p
                          className={
                            styles["card-collapse-step-data-item-value"]
                          }
                        >
                          {containers.map((item) => (
                            <div key={item.type}>
                              {item.name} (x{item.number})
                              <br />
                            </div>
                          ))}
                        </p>
                      </div>
                    ) : null}
                    {containers?.length > 0 ? (
                      <div className={styles["card-collapse-step-data-item"]}>
                        <p
                          className={
                            styles["card-collapse-step-data-item-label"]
                          }
                        >
                          Weight Per Container
                        </p>
                        <p
                          className={
                            styles["card-collapse-step-data-item-value"]
                          }
                        >
                          {containers.map((item) => (
                            <div key={item.type}>
                              {item.weight} kg
                              <br />
                            </div>
                          ))}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}

            {/* Additional Information */}
            {(target_rate > 0 || additional_info) && (
              <div className={styles["card-collapse-step"]}>
                <h3>Additional Info</h3>
                <div
                  className={`${styles["card-collapse-step-data"]} ${styles["card-collapse-step-data-col"]}`}
                >
                  {target_rate > 0 && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Target Rate
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {target_rate}
                      </p>
                    </div>
                  )}
                  {additional_info && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Others
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {additional_info}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Carousel>
        </div>
        {/* Requests Details For Web View */}
        <div className={styles["card-collapse"]}>
          {/* Shipping Informations */}
          <div className={styles["card-collapse-step"]}>
            <h3>Shipping</h3>
            <div className={styles["card-collapse-step-data"]}>
              {port_of_loading && (
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    Port Of Loading (POL)
                  </p>
                  <p className={styles["card-collapse-step-data-item-value"]}>
                    {port_of_loading}
                  </p>
                </div>
              )}
              {port_of_discharge && (
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    Port Of Discharge (POD)
                  </p>
                  <p className={styles["card-collapse-step-data-item-value"]}>
                    {port_of_discharge}
                  </p>
                </div>
              )}
              {shipping_line?.name && (
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    Shipping Line
                  </p>
                  <p className={styles["card-collapse-step-data-item-value"]}>
                    {shipping_line?.name}
                  </p>
                </div>
              )}
              {pod_free_time > 0 && (
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    POD Free Time
                  </p>
                  <p className={styles["card-collapse-step-data-item-value"]}>
                    {pod_free_time} days
                  </p>
                </div>
              )}
              {required_services && (
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    Required Services
                  </p>
                  <p className={styles["card-collapse-step-data-item-value"]}>
                    {required_services?.map((item) => (
                      <div key={item.id}>
                        {item.title}
                        <br />
                      </div>
                    ))}
                  </p>
                </div>
              )}
              {invoice_value > 0 && (
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    Invoice Value
                  </p>
                  <p className={styles["card-collapse-step-data-item-value"]}>
                    {invoice_value}$
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* Truking Informations */}
          {(origin_country || destination_country) && (
            <>
              <div className={styles["card-collapse-separator"]} />
              <div className={styles["card-collapse-step"]}>
                <h3>Trucking</h3>
                {/* Origin Details Informations */}
                {origin_country && (
                  <div className={styles["card-collapse-step"]}>
                    <div className={styles["card-collapse-step-data"]}>
                      {origin_country && (
                        <div className={styles["card-collapse-step-data-item"]}>
                          <p
                            className={
                              styles["card-collapse-step-data-item-label"]
                            }
                          >
                            Origin Country
                          </p>
                          <p
                            className={
                              styles["card-collapse-step-data-item-value"]
                            }
                          >
                            {origin_country}
                          </p>
                        </div>
                      )}
                      {origin_state && (
                        <div className={styles["card-collapse-step-data-item"]}>
                          <p
                            className={
                              styles["card-collapse-step-data-item-label"]
                            }
                          >
                            Origin State
                          </p>
                          <p
                            className={
                              styles["card-collapse-step-data-item-value"]
                            }
                          >
                            {origin_state}
                          </p>
                        </div>
                      )}
                      {origin_address && (
                        <div className={styles["card-collapse-step-data-item"]}>
                          <p
                            className={
                              styles["card-collapse-step-data-item-label"]
                            }
                          >
                            origin Address
                          </p>
                          <p
                            className={
                              styles["card-collapse-step-data-item-value"]
                            }
                          >
                            {origin_address}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* Destination Details Informations */}
                {destination_country && (
                  <div className={styles["card-collapse-step"]}>
                    <div className={styles["card-collapse-step-data"]}>
                      {destination_country && (
                        <div className={styles["card-collapse-step-data-item"]}>
                          <p
                            className={
                              styles["card-collapse-step-data-item-label"]
                            }
                          >
                            Destination Country
                          </p>
                          <p
                            className={
                              styles["card-collapse-step-data-item-value"]
                            }
                          >
                            {destination_country}
                          </p>
                        </div>
                      )}
                      {destination_state && (
                        <div className={styles["card-collapse-step-data-item"]}>
                          <p
                            className={
                              styles["card-collapse-step-data-item-label"]
                            }
                          >
                            Destination State
                          </p>
                          <p
                            className={
                              styles["card-collapse-step-data-item-value"]
                            }
                          >
                            {destination_state}
                          </p>
                        </div>
                      )}
                      {destination_address && (
                        <div className={styles["card-collapse-step-data-item"]}>
                          <p
                            className={
                              styles["card-collapse-step-data-item-label"]
                            }
                          >
                            Destination Address
                          </p>
                          <p
                            className={
                              styles["card-collapse-step-data-item-value"]
                            }
                          >
                            {destination_address}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className={styles["card-collapse-separator"]} />
          {/* Cargo Details Informations */}
          <div className={styles["card-collapse-step"]}>
            <h3>Cargo Details</h3>
            <div className={styles["card-collapse-step-data"]}>
              <div className={styles["card-collapse-step-data-item"]}>
                <p className={styles["card-collapse-step-data-item-label"]}>
                  Readiness Date
                </p>
                <p className={styles["card-collapse-step-data-item-value"]}>
                  {moment(readiness_date).format("D MMM YYYY")}
                </p>
              </div>
              {commodity && (
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    Commodity
                  </p>
                  <p
                    className={styles["card-collapse-step-data-item-value"]}
                    style={{
                      minWidth: "450px",
                      maxWidth: "450px",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      overflow: "hidden",
                    }}
                  >
                    {commodity}
                  </p>
                </div>
              )}
              <div className={styles["card-collapse-step-data-item"]}>
                <p className={styles["card-collapse-step-data-item-label"]}>
                  Dangerous Cargo
                </p>
                <p className={styles["card-collapse-step-data-item-value"]}>
                  {dangerous_cargo ? "Yes" : "No"}
                </p>
              </div>

              <div style={{ display: "flex", gap: "2rem" }}>
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    Containers
                  </p>
                  <p className={styles["card-collapse-step-data-item-value"]}>
                    {containers?.map((item) => (
                      <div key={item.type}>
                        {item.name} (x{item.number})
                        <br />
                      </div>
                    ))}
                  </p>
                </div>
                <div className={styles["card-collapse-step-data-item"]}>
                  <p className={styles["card-collapse-step-data-item-label"]}>
                    Weight Per Container
                  </p>
                  <p className={styles["card-collapse-step-data-item-value"]}>
                    {containers?.map((item) => (
                      <div key={item.type}>
                        {item.weight} kg
                        <br />
                      </div>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {(target_rate > 0 || additional_info) && (
            <div className={styles["card-collapse-separator"]} />
          )}
          {/* Additional Information */}
          {(target_rate > 0 || additional_info) && (
            <div className={styles["card-collapse-step"]}>
              <h3>Additional Info</h3>
              <div className={`${styles["card-collapse-step-data"]}`}>
                {target_rate > 0 && (
                  <div className={styles["card-collapse-step-data-item"]}>
                    <p className={styles["card-collapse-step-data-item-label"]}>
                      Target Rate
                    </p>
                    <p className={styles["card-collapse-step-data-item-value"]}>
                      {target_rate}$
                    </p>
                  </div>
                )}
                {additional_info && (
                  <div className={styles["card-collapse-step-data-item"]}>
                    <p className={styles["card-collapse-step-data-item-label"]}>
                      Others
                    </p>
                    <p className={styles["card-collapse-step-data-item-value"]}>
                      {additional_info}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Collapse>
      {expanded && request_status === "pending" && !isMobile ? null : (
        <div className={`${styles["card-row"]} ${styles["card-row-top"]}`}>
          <div
            className={`${styles["card-row-time"]} ${styles["card-row-time-res"]}`}
          >
            <img src={clock} alt="clock" />
            <p>{moment(created_at).format("h:mm a, D MMM YYYY")}</p>
          </div>
          {!expanded && (
            <div className={styles["card-row-data"]}>
              {shipping_line?.name && (
                <p>
                  Shipping Line: <span>{shipping_line?.name}</span>
                </p>
              )}
              {commodity && (
                <p>
                  Commodity: <span>{commodity}</span>
                </p>
              )}
              <div className={styles["card-shipping-row-item-readiness"]}>
                <p>
                  Readiness:{" "}
                  <span>{moment(readiness_date).format("D MMM YYYY")}</span>
                </p>
              </div>
            </div>
          )}
          {request_status !== "pending" && (
            <MainButton
              handleSubmit={() => {
                setShowModal(true);
                handleViewClick(id);
              }}
            >
              View Quote
            </MainButton>
          )}
        </div>
      )}
      {showModal && (
        <QuoteModal
          handleClose={() => {
            setShowModal(false);
            if (request_status === "active" && !seen) getRequests();
          }}
          isOpen={showModal}
          title={"Quotation"}
          id={id}
          pdfUrl={attachment}
          expiration_date={expiration_date}
          request_status={request_status}
          getRequests={getRequests}
          setShowOfferModal={setShowModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      <SuccessModal
        isOpen={showSuccessModal}
        handleSuccess={handleSuccess}
        title="Quotation Accepted"
        body="We are glad you have accepted the submitted quotation. One of our
            sales representatives shall contact you soon either by phone or
            email to arrange for booking your shipment. Please prepare any
            relevant documents such as shipping declaration."
      />
    </div>
  );
};

export default memo(RequestCard);
