export default function formatDateToYYYYMMDD(currentDate) {
  const date = new Date(currentDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  // Add leading zeros if necessary
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
}

export const tableDateFormat = (date) => {
  const dateObj = new Date(date);
  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const am_pm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const day = dateObj.getDate().toString().padStart(2, "0");
  const year = dateObj.getFullYear();
  const month = dateObj.toLocaleString("en-US", { month: "short" });
  return (
    <>
      {day} {month} {year}
      <br />
      {hours}:{minutes} {am_pm}
    </>
  );
};
