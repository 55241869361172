import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import close from "../../assets/close-icon.svg";
import FormStepper from "../../components/form-stepper";
import { getRequestQuoteData } from "../../services/contentService";
import infoIcon from "../../assets/info-icon.svg";
import styles from "./index.module.scss";

const RequestQuoteForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [inputsData, setInputsData] = useState({});
  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});
  const [rows, setRows] = useState([{ id: 1, containers: [], weight: "" }]);
  const [stepThreeData, setStepThreeData] = useState({});
  const [stepFourData, setStepFourData] = useState({});
  const [stepFiveData, setStepFiveData] = useState({});
  const [freight, setFreight] = useState({});
  const [freightsSearch, setFreightsSearch] = useState([]);
  const [prevLocation, setPrevLocation] = useState("");
  const [schedulesInputsValues, setSchedulesInputsValues] = useState({});

  const isPolTrucking = () => {
    const requiredServices = stepOneData?.requiredServices;
    if (requiredServices !== undefined) {
      return requiredServices.some((obj) => obj.title === "POL Trucking");
    } else {
      return false;
    }
  };
  const isPodTrucking = () => {
    const requiredServices = stepOneData?.requiredServices;
    if (requiredServices !== undefined) {
      return requiredServices.some((obj) => obj.title === "POD Trucking");
    } else {
      return false;
    }
  };

  const location = useLocation();

  const navigate = useNavigate();
  const { step } = useParams();
  const steps = [
    { name: "Shipping", slug: "shipping-details", step: 0 },
    {
      name: "Trucking",
      slug: "trucking-details",
      step: 1,
      isPolTrucking: !isPolTrucking(),
      isPodTrucking: !isPodTrucking(),
    },
    {
      name: "Cargo",
      slug: "cargo-details",
      step: isPolTrucking() || isPodTrucking() ? 2 : 1,
    },
    {
      name: "Other",
      slug: "other",
      step: isPolTrucking() || isPodTrucking() ? 3 : 2,
      except: true,
    },
    { name: "Success", slug: "success", step: 4 },
  ];

  const stepData = steps.find((item) => item.slug === step);

  const getInputsData = async () => {
    try {
      const response = await getRequestQuoteData();
      if (response.status === 200) {
        //console.log(response.data);
        setInputsData(response.data);
      }
    } catch (err) {
      //console.log("err", err);
    }
  };

  useEffect(() => {
    setCurrentStep(stepData.step);
  }, [step]);

  useEffect(() => {
    if (location.state?.freight) setFreight(location.state.freight);
    if (location.state?.freightsSearch)
      setFreightsSearch(location.state.freightsSearch);
    if (location.state?.previousPath)
      setPrevLocation(location.state.previousPath);
    if (location.state?.schdulesSearchValues)
      setSchedulesInputsValues(location.state.schdulesSearchValues);
    getInputsData();
  }, []);

  useEffect(() => {
    // console.log("freightsSearch", freightsSearch);
  }, [freightsSearch]);

  return (
    <div className={styles["request-quote"]}>
      <div className={styles["request-quote-form"]}>
        {step !== "success" && (
          <>
            <div className={styles["request-quote-form-header"]}>
              <h3>Request a Quote</h3>
              <button
                onClick={() =>
                  navigate(prevLocation, {
                    state: {
                      quoteLocation: location.pathname,
                      searchId: freight?.search_id,
                      quoteFreightsSearch: freightsSearch,
                      quoteSearchResults: schedulesInputsValues,
                    },
                  })
                }
              >
                <img src={close} alt="close" className={styles["close-img"]} />
              </button>
            </div>
            <hr />
            <p>
              {stepData?.step + 1}/
              {isPolTrucking() || isPodTrucking() ? "4" : "3"} Steps
            </p>
            <FormStepper
              currentStep={currentStep}
              steps={steps.slice(0, steps.length - 1)}
            />
          </>
        )}
        <Outlet
          context={{
            inputsData,
            stepOneData,
            setStepOneData,
            stepTwoData,
            setStepTwoData,
            stepThreeData,
            setStepThreeData,
            stepFourData,
            setStepFourData,
            stepFiveData,
            setStepFiveData,
            infoIcon,
            freight,
            freightsSearch,
            schedulesInputsValues,
            prevLocation,
            isPolTrucking,
            isPodTrucking,
            rows,
            setRows,
          }}
        />
      </div>
    </div>
  );
};

export default RequestQuoteForm;
