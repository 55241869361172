import React, { useEffect, useState } from "react";
import styles from "./index.module.scss"; // SCSS file
import phone from "../../assets/Vector.svg";
import mail from "../../assets/mail.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectClientCompany,
  selectClientEmail,
  selectClientName,
  selectClientPhone,
  selectOpenedRequestID,
} from "../../store/slices/salesRequestsSlice";
import { getRequestInfo } from "../../services/contentService";
import Spinner from "../Spinner";

const ClientDetails = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const select = useSelector;
  const username = select(selectClientName);
  const email = select(selectClientEmail);
  const phoneNumber = select(selectClientPhone);
  const company = select(selectClientCompany);
  const ID = select(selectOpenedRequestID);
  const token = localStorage.getItem("token");
  const [requestInfo, setRequestInfo] = useState(null);
  const dispatch = useDispatch();
  const fetchRequestDetails = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/request?id=${id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await getRequestInfo(config);

    if (response?.status === 200) {
      setRequestInfo(response?.data?.data);
    } else {
      //console.log("error :", response);
    }
  };
  useEffect(() => {
    if (id) {
      fetchRequestDetails();
    }
  }, [id]);
  setTimeout(() => {
    setLoading(false);
  }, 1500);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.clientDetailsCard}>
          <h3>Client Details</h3>
          <div className={styles.card}>
            <div className={styles.clientInfo}>
              <img
                className={styles.avatar}
                src={
                  requestInfo?.client_picture
                    ? requestInfo?.client_picture
                    : "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
                }
                alt=""
              />
              <div className={styles.nameAndPosition}>
                <h4>
                  {requestInfo?.client_fname +
                    " " +
                    requestInfo?.client_lname || ""}
                </h4>
                <p>
                  {requestInfo?.client_company
                    ? requestInfo?.client_company
                    : ""}{" "}
                  {requestInfo?.client_position
                    ? " - " + requestInfo?.client_position
                    : ""}
                </p>
              </div>
            </div>
            <div className={styles.contactInfo}>
              <div className={styles.contactItem}>
                <img src={mail} alt="Email Icon" />
                <p>
                  {requestInfo?.client_email ? requestInfo?.client_email : ""}
                </p>
              </div>
              {requestInfo?.client_phone ? (
                <div className={styles.contactItem}>
                  <img src={phone} alt="Phone Icon" />
                  <p>{requestInfo?.client_phone}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientDetails;
