import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Radio,
  Typography,
} from "@mui/material";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import {
  setCargoType,
  setIsCargoTypeChanged,
} from "../../store/slices/searchSlice";

const CargoDropdown = ({
  options,
  label = "Cargo",
  placeholder = "Select cargo",
  className = "",
  quotationsearchCargo,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();

  // AutoFilling Cargo Dropdown When It Comes From Request Quotation Page.
  useEffect(() => {
    if (quotationsearchCargo) {
      setSelectedValue(quotationsearchCargo?.name);
    }
  }, [quotationsearchCargo]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(value);
    let selectedCargo = options.filter((option) => option.name === value);
    dispatch(setCargoType(...selectedCargo));
    dispatch(setIsCargoTypeChanged(true));
  };

  return (
    <FormControl
      className={`${[styles["cargo-dropdown"], className].join(" ")}`}
    >
      <div className={styles["label"]}>
        <label>{label}</label>
      </div>

      <Select
        labelId="radio-dropdown-label"
        id="radio-dropdown"
        value={selectedValue}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => {
          return (
            selected || (
              <Typography sx={{ color: "#808B8C" }}>{placeholder}</Typography>
            )
          );
        }}
        // Custom styling
        sx={{
          borderRadius: "10px",
          textTransform: "capitalize",
          // Accessing the select field.
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(202, 202, 202, 0.6980392157)",
            borderWidth: "0.8px",
          },
          // The border custom styles on hover.
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(202, 202, 202, 0.6980392157)",
          },
          // The border custom styles on focus.
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(202, 202, 202, 0.6980392157)",
          },
          // Accessing the dropdown arrow.
          "& .MuiSelect-icon": {
            display: "none",
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              value={option.name}
              key={option.id}
              sx={{ textTransform: "capitalize" }}
            >
              <Radio checked={selectedValue === option.name} /> {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CargoDropdown;
